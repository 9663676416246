import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Admin from '../Admin';
import SignInPage from '../SignIn';
import QuizPage from '../Quiz';

import { AuthUserContext } from '../Session';
import { withAuthentication } from '../Session';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(9, 0),
    }
}));

const App = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md" className={classes.root}>
            <Router>
                <Switch>
                    <Route path='/admin'>
                        <AuthUserContext.Consumer>
                            {authUser => authUser ? <Admin /> : <SignInPage isAdmin /> }
                        </AuthUserContext.Consumer>
                    </Route>
                    <Route path='/'>
                        <AuthUserContext.Consumer>
                            {authUser => authUser ? <QuizPage /> : <SignInPage /> }
                        </AuthUserContext.Consumer>
                    </Route>
                </Switch>
            </Router>
        </Container>
    );
}

export default withAuthentication(App);

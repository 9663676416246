import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

const withAuthentication = Component => {

    class WithAuthentication extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                authUser: null,
                isFetching: true,
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    if (authUser) {
                        this.setState({ authUser, isFetching: false });
                    } else {
                        this.setState({ authUser: null, isFetching: false });
                    }
                },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    {this.state.isFetching && (
                      <div 
                        style={{ 
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          display: 'flex',
                          flex: 1, 
                          justifyContent: 'center', 
                          alignItems: 'center',
                        }}
                        children={<CircularProgress />}
                      />
                    )}
                    {!this.state.isFetching && <Component {...this.props} />}
                </AuthUserContext.Provider>
            );
        }

    }

    return withFirebase(WithAuthentication);
};

export default withAuthentication;
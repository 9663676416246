import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
};

export default class Firebase {

    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.functions = app.functions();
    }

    doCallFunction(tags, input, blurring = [], submit = false) {
        return this.functions.httpsCallable('quiz')({ tags, input, submit, blurring });
    }

    doSignInWithEmailAndPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut = () => {
        return this.auth.signOut();
    }
}

import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const QUIZ_LINK = {
    'Frontend Developer': 'https://newtalent.mirumagency.hu#frontend-react',
    'Frontend/React Developer': 'https://newtalent.mirumagency.hu#frontend-react',
    'Sitecore & .NET Developer': 'https://newtalent.mirumagency.hu#dotnet',
    'UI Designer': 'https://newtalent.mirumagency.hu',
    'Technical Project Manager': 'https://newtalent.mirumagency.hu',
  };

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
      textAlign: 'center',
      marginBottom: 20,
      textTransform: 'uppercase',
    },
}));

const Admin = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <Icon>lock</Icon>
                </Avatar>
                <Typography component="h1" variant="h4" className={classes.title}>Wunderman Thompson Budapest Hiring Admin SYSTEM</Typography>
                <Typography component="h5">Send test to candidate</Typography>
                <SendingForm className={classes.form} />
            </div>
        </Container>
    );
}

const INITIAL_STATE = {
    email: '',
    name: '',
    test: '',
    error: null,
    success: null
};

class SendingForm extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onSubmit = async event => {
        event.preventDefault();
        const { email, name: fullName, test: position } = this.state;

        const serviceURL = 'https://us-central1-hr-quiz.cloudfunctions.net/chat/submit/';
    
        await fetch(`${serviceURL}`, {
            method: 'POST', 
            credentials: 'include',
            body: JSON.stringify({ 
                action: 'FINISH',
                endpoint: '/submit',
                params: {
                    email,
                    fullName, 
                    position
                },
            }), 
        }).catch((e) => {
            console.log(e);
        });

        /*
        .then((response) => {
            return response.json()
        })
        .then(data => {
            console.log('gecis data');
            console.log(data);
            this.setState({ 
                error: !data.success ? { message: data.text }: null,
                success: data.success ? { message: `Test has just been sent to ${fullName} <${email}>` } : null,
            });

            return data;
        });
         */
    }

    render() {
        const { email, name, error, success, test } = this.state;
        const isInvalid = name === '' || email === '' || test === '';
        return (
            <form onSubmit={this.onSubmit} noValidate>
                <TextField
                    variant="outlined"
                    name="name"
                    label="Name"
                    value={name}
                    onChange={this.onChange}
                    required
                    fullWidth
                    margin="normal"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    name="email"
                    label="Email Address"
                    value={email}
                    onChange={this.onChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <FormControl variant="outlined" fullWidth required margin="normal">
                    <InputLabel htmlFor="ide">Test</InputLabel>
                    <Select
                        label="Test"
                        name="test"
                        id="test"
                        value={test}
                        onChange={this.onChange}
                        >
                        {Object.entries(QUIZ_LINK).map((entry) => (
                            <MenuItem value={entry[0]}>{entry[0]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isInvalid}
                    fullWidth
                    type="submit"
                >
                    Send
                </Button>
                {error && <p style={{ color: 'red' }}>{error.message}</p>}
                {success && <p style={{ color: 'green' }}>{success.message}</p>}
            </form>
        );
    }
}

// const SignInForm = withFirebase(SignInFormBase);

export default Admin;

// export { SignInForm }
import React from 'react';
import Fab from '@material-ui/core/Fab';
import { withFirebase } from '../Firebase';
import Icon from '@material-ui/core/Icon';

function SignOutButton(props) {

    return (
        <Fab title="Sign Out" size="small" color="secondary" onClick={props.firebase.doSignOut}>
            <Icon>power_settings_new</Icon>
        </Fab>
    );
}

export default withFirebase(SignOutButton);
import React, { Component } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
      textAlign: 'center',
      marginBottom: 20,
      textTransform: 'uppercase',
    },
}));

const SignInPage = ({ isAdmin }) => {
    const classes = useStyles();
    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <Icon>lock</Icon>
                </Avatar>
                <Typography component="h1" variant="h4" className={classes.title}>Wunderman Thompson Budapest Hiring {isAdmin ? 'Admin': ''} SYSTEM</Typography>
                <Typography component="h5">Sign in and Start</Typography>
                <SignInForm className={classes.form} isAdmin={isAdmin} />
            </div>
        </Container>
    );
}

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};

class SignInFormBase extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onChange = event => {
        this.setState({ [event.target.name]: 
          event.target.type.toLowerCase() === 'checkbox' ? event.target.checked : event.target.value
        });
    }

    onSubmit = event => {
        event.preventDefault();
        const { email, password } = this.state;

        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
          })
          .catch(error => {
            this.setState({ error });
          });
    }

    render() {
        const { isAdmin } = this.props;
        const { email, password, error, terms } = this.state;
        const isInvalid = password === '' || email === '' || (!terms && !isAdmin);
        return (
            <form onSubmit={this.onSubmit} noValidate>
                <TextField
                    variant="outlined"
                    name="email"
                    label="Email Address"
                    value={email}
                    onChange={this.onChange}
                    required
                    fullWidth
                    margin="normal"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    name="password"
                    label="Password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    required
                    fullWidth
                    margin="normal"
                />
                {!isAdmin && <div
                  style={{
                    margin: '20px 0',
                  }}
                >
                  <Checkbox
                      variant="outlined"
                      name="terms"
                      onChange={this.onChange}
                      required
                      fullWidth
                      margin="normal"
                  />
                  <label 
                    style={{
                      margin: 8,
                    }}
                  >
                    I have 15 minutes for this test
                  </label>
                </div>}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isInvalid}
                    fullWidth
                    type="submit"
                >
                    Sign In
                </Button>
                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = withFirebase(SignInFormBase);

export default SignInPage;

export { SignInForm }
import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import Navigation from '../Navigation';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
    },
    title: {
        margin: theme.spacing(1, 0),
    },
    result: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(8, 2),
        margin: theme.spacing(2, 0),
    },
    avatar: {
        margin: theme.spacing(1),
        width: 60,
        height: 60,
        backgroundColor: theme.palette.primary.main,
    },
    button: {
        margin: theme.spacing(3, 0),
        textAlign: "center",
    },
    question: {
        margin: theme.spacing(1),
        whiteSpace: "pre-wrap"
    },
    answers: {
      paddingTop: 12,
    },
    answer: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        margin: theme.spacing(1),
    },
    footertext: {
      display: 'block',
      paddingTop: 20,
      paddingLeft: 10,
      fontStyle: 'italic',
      color: '#666',
      fontSize: 14,
    },
}));

const handler = (event) => {
  event = event || window.event;

  if (event.stopPropagation)
      event.stopPropagation();

  event.cancelBubble = true;
  return false;
}

const Question = ({question, code, answers, checked, onChange, isRadio, index }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root} onContextMenu={handler}>
            <Typography variant="h6" component="pre" className={classes.question}>
                {question}
            </Typography>
            {code && (
              <pre 
                onClick={handler} 
                onMouseDown={handler} 
                onMouseUp={handler}
              >
                <code children={code} />
              </pre>
            )}
            <div className={classes.answers}>
              {answers.map((answer, i) => {
                  return (
                    <div key={i} className={classes.answer}>
                        {isRadio && (<Radio name={`radio${index}`} onChange={(event) => onChange(event, i)} value={i} checked={checked[i]} />)}
                        {!isRadio && (<Checkbox onChange={(event) => onChange(event, i)} value={i} checked={checked[i]} />)}
                        <label className={classes.label}>{answer.answer}</label>
                    </div>
                  )
              })}
            </div>
            {!isRadio && (
              <Typography variant="i" className={classes.footertext}>* Please check all the correct answers</Typography>
            )}
        </Paper>
    );
}

const SubmitButton = ({loading, onClick}) => {
    const classes = useStyles();
    return (
        <div className={classes.button}>
        {loading
            ? <CircularProgress thickness={5} />
            : <Button variant="contained" color="primary" onClick={onClick}>Submit</Button>}
        </div>
    );
}

const Results = ({score, time, pass}) => {
    const classes = useStyles();
    return (
        <Paper className={classes.result}>
            <Avatar className={classes.avatar}>
                {pass ? <Icon>thumb_up</Icon> : <Icon>thumb_down</Icon>}
            </Avatar>
            <Typography variant="h4" className={classes.title}>
                {pass ? 'Congrats!' : 'Almost there!'}
            </Typography>
            <Typography variant="h5">
                You answered: {score}% of the questions correctly.
            </Typography>
            <Typography variant="h5">
                {pass ? 
                    'Our HR assistant will get in touch with you soon!' 
                    : 'Do some more practice and retry this test later.'
                }
            </Typography>
        </Paper>
    );
}

class Quiz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            time: 0,
            quiz: {
                questions: []
            },
            blurring: [],
            input: [],
            tags: [],
        };
    }

    onChange = (event, question, answer) => {
        const input = this.state.input;
        if (this.state.quiz.questions[question].isRadio) {
          input[question] = input[question].map(() => false);  
        }
        input[question][answer] = event.target.checked;

        this.setState({input});
        if (this.save) {
            window.clearTimeout(this.save);
        }
        this.save = window.setTimeout(() => {
            this.props.firebase.doCallFunction(this.state.tags, input, this.state.blurring);
            this.save = null;
        }, 3000);
        return true;
    }

    onSubmit = (event) => {
        this.submit();
    }

    async submit() {

        if (this.save) {
            window.clearTimeout(this.save);
        }
        this.setState({loading: true});
        const result = await this.props.firebase.doCallFunction(this.state.tags, this.state.input, this.state.blurring, true);
        this.setState({
            time: 0,
            quiz: result.data,
            loading: false,
        });
        window.clearInterval(this.timer);
    }

    onWindowFocus = () => {
        const { blurring, time, input } = this.state;

        if (!blurring.length) return;

        blurring[blurring.length - 1].focusTime = time;

        this.setState({ blurring });
        this.save = window.setTimeout(() => {
            this.props.firebase.doCallFunction(this.state.tags, input, blurring);
            this.save = null;
        }, 3000);
    }

    onWindowBlur = () => {
        const { blurring, time, input } = this.state;

        blurring.push({
            blurTime: time,
            questions: input.map((answer, index) => ({
                id: index,
                answers: answer.map(input => ({
                    input,
                })),
             })),
        });

        this.setState({ blurring });
        this.save = window.setTimeout(() => {
            this.props.firebase.doCallFunction(this.state.tags, input, blurring);
            this.save = null;
        }, 3000);
    }

    async componentDidMount() {
        window.addEventListener("focus", this.onWindowFocus);
        window.addEventListener("blur", this.onWindowBlur);
        const query = window.location.hash.match(/([+\-a-z]+)/);

        const tags = query ? query[1].split('+') : [];
            const result = await this.props.firebase.doCallFunction(tags);
            const quiz = result.data;
            const input = quiz.questions.map(question => question.answers.map(answer => answer.input === true));
        this.setState({quiz, tags, input, loading: false});
        if (!quiz.time) {
            this.timer = window.setInterval(() => {
                const time = Math.round(quiz.timelimit - (new Date().getTime() - quiz.created) / 1000);
                if (time > 0) {
                    this.setState({time});
                } else {
                    this.submit();
                }
            }, 1000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onWindowFocus);
        window.removeEventListener("blur", this.onWindowBlur);
    }

    render() {
        const quiz = this.state.quiz;
        return (
            <Container>
                <Navigation time={this.state.time} limit={this.state.quiz.timelimit} />
                {quiz.time &&
                    <Results
                        time={Math.round(quiz.time / 1000)}
                        score={quiz.score}
                        pass={quiz.score >= quiz.requirement} />}
                {!quiz.time && quiz.questions.map((question, i) =>
                    <Question
                        key={i}
                        index={i}
                        {...question}
                        checked={this.state.input[i]}
                        onChange={(event, answer) => this.onChange(event, i, answer)}
                    />)}
                {!quiz.time &&
                    <SubmitButton
                        loading={this.state.loading}
                        onClick={this.onSubmit} />}
            </Container>
        );
    }
}

export default withFirebase(Quiz);

import React from 'react';

const TimeFormat = ({time, className}) => {
    const min = Math.floor(time / 60);
    const sec = String(time % 60 + 100).substr(1);
    const text = min ? `${min}:${sec}` : sec;
    return (
        <span className={className}>{time ? text : null}</span>
    );
}

export default TimeFormat;
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import SignOutButton from '../SignOut';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import TimeFormat from '../TimeFormat';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        fontSize: 22,
        textTransform: 'uppercase',
    },
    time: {
        padding: theme.spacing(0, 2),
    },
    container: {
        padding: theme.spacing(0, 1),
    }
}));

const Navigation = ({time}) => {
    const classes = useStyles();
    return (
        <AppBar position="fixed">
            <Container maxWidth="md" className={classes.container}>
                <Toolbar>
                    <Typography variant="h4" className={classes.title}>
                        Wunderman Thompson Budapest HIRING SYSTEM
                    </Typography>
                    <Typography variant="h5" className={classes.time}>
                        <TimeFormat time={time} className={time < 30 ? 'blink' : ''} />
                    </Typography>
                    <SignOutButton />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navigation;
